import React, { ReactNode } from 'react'
import { IntlProvider } from 'react-intl'
import ja from 'gen/locale/ja.json'

type Props = React.PropsWithChildren<{
  children: ReactNode
}>

export const Provider: React.FC<Props> = ({ children }) => {
  return (
    <IntlProvider locale="ja" messages={ja}>
      {children}
    </IntlProvider>
  )
}
