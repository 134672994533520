import { PropsWithChildren } from 'react'
import { Text, TextProps } from '@chakra-ui/react'

/** フォームの入力ヒント用のスタイルされたテキストコンポーネント */
export const HelpText: React.FC<PropsWithChildren<TextProps>> = ({ children }) => {
  return (
    <Text fontSize="xs" color="gray.600" mt={1}>
      {children}
    </Text>
  )
}
