import { useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Button,
  Text,
} from '@chakra-ui/react'

type ContainerProps = {
  title: string
  imageTitle?: string
  isOpen: boolean
  file: File | null
  onClose: () => void
  handleSubmit: () => void
  isSubmitting: boolean
}

type Props = Omit<ContainerProps, 'file'> & {
  previewUrl: string | null
}

const Component: React.FC<Props> = ({
  title,
  imageTitle,
  isOpen,
  onClose,
  previewUrl,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" justifyContent="center">
          {previewUrl !== null ? (
            <>
              <Image src={previewUrl} alt={imageTitle ?? title} />
              {imageTitle !== undefined && (
                <Text fontSize="xl" mt={3}>
                  {imageTitle}
                </Text>
              )}
            </>
          ) : (
            '画像が正しくアップロードされませんでした'
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isSubmitting}>
            保存
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const Container: React.FC<ContainerProps> = ({ file, ...rest }) => {
  const previewUrl = useMemo(() => {
    return file ? window.URL.createObjectURL(file) : null
  }, [file])

  return <Component {...rest} previewUrl={previewUrl} />
}

export default Container
