import { useContext } from 'react'
import { DialogOptions } from 'components/ui/Dialog'
import { Context } from './context'

type PartialOptions = Omit<DialogOptions, 'variant'>

const useConfirmation = () => {
  const openConfirmationModal = useContext(Context)

  const alert = (options: PartialOptions) => {
    return openConfirmationModal({ ...options, variant: 'alert' })
  }

  const confirm = (options: PartialOptions) => {
    return new Promise<boolean>(resolve => {
      openConfirmationModal({
        ...options,
        variant: 'confirm',
        catchOnCancel: true,
      })
        .then(() => resolve(true))
        .catch(() => resolve(false))
    })
  }

  return {
    alert,
    confirm,
  }
}

export default useConfirmation
