import { ReactElement } from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Link from '../Link'

type ValueType = 'text' | 'url'
type Props = {
  type: ValueType
  value: string | number | ReactElement
  url: string
}

const Component: React.FC<Props> = ({ type, url, value }) => {
  switch (type) {
    case 'url': {
      return (
        <Link
          href={url}
          fontFamily="monospace"
          color="blue.600"
          wordBreak="break-all"
          target="_blank"
          rel="noreferrer"
        >
          <ExternalLinkIcon mr={2} />
          {value}
        </Link>
      )
    }
    case 'text': {
      return <>{value}</>
    }
    default: {
      return null
    }
  }
}

export default Component
