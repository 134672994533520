import React from 'react'
import { Heading, Box, Stack, Text, Button } from '@chakra-ui/react'
import { useAuthState } from 'context/auth'

type ContainerProps = {
  title: string
  description?: string
}

type Props = {
  signedIn: boolean
} & ContainerProps

const Component: React.FC<Props> = ({ title, description, signedIn }) => {
  return (
    <Stack textAlign="center" height="100%" spacing={5} justifyContent="center" alignItems="center">
      <Box>
        <Heading size="lg" mb={4}>
          {title}
        </Heading>
        {description !== undefined && <Text color="deepgray">{description}</Text>}
      </Box>
      <Stack mt={4} spacing={4}>
        <Button colorScheme="blue" fontSize="md" w="full" onClick={() => window.location.reload()}>
          リロード
        </Button>
        <a href={signedIn ? '/' : '/sign_up'}>
          <Button colorScheme="blue">トップへ戻る</Button>
        </a>
      </Stack>
    </Stack>
  )
}

const Container: React.FC<ContainerProps> = props => {
  const { signedIn } = useAuthState()

  return <Component {...props} signedIn={signedIn} />
}

export default Container
