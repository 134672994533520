import { Thead, Tr, Th } from '@chakra-ui/react'

type Props = {
  enableChangePosition: boolean
  headerContent: React.ReactElement
}

const Component: React.FC<Props> = ({ enableChangePosition, headerContent }) => {
  return (
    <Thead bg="gray.50">
      <Tr>
        {enableChangePosition && <Th w={0} />}
        {headerContent}
        {enableChangePosition && <Th w={0} />}
      </Tr>
    </Thead>
  )
}

export default Component
