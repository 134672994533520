import Link from 'next/link'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'

type Props = {
  items: { title: string; href: string; isCurrentPage?: boolean }[]
}

const Component: React.FC<Props> = ({ items }) => {
  return (
    <Box bg="white" rounded="lg" shadow="base" p={3}>
      <Breadcrumb separator={<ChevronRightIcon color="gray.500" />}>
        {items.map(item => (
          <BreadcrumbItem key={item.title} isCurrentPage={item.isCurrentPage}>
            <Link href={item.href} passHref>
              <BreadcrumbLink isCurrentPage={item.isCurrentPage}>{item.title}</BreadcrumbLink>
            </Link>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
  )
}

export default Component
