import React from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  RadioProps,
  FormErrorMessage,
  RadioGroup,
  Radio,
  Stack,
  StackDirection,
  Text,
} from '@chakra-ui/react'

import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  name: UseFormRegisterReturn['name']
  options: { id: string; body: string }[]
  label?: string
  errorMessage?: string
  isRequired?: boolean
  radioProps?: RadioProps
  direction?: StackDirection
  onChangeCallback?: (v: string) => void
}

const Component: React.FC<Props> = ({
  label,
  errorMessage,
  control,
  options,
  isRequired,
  name,
  radioProps,
  direction = 'row',
  onChangeCallback,
}) => {
  return (
    <FormControl isInvalid={errorMessage !== undefined} isRequired={isRequired}>
      {label !== undefined && (
        <Flex justify="space-between">
          <FormLabel>{label}</FormLabel>
        </Flex>
      )}
      <Controller
        control={control}
        render={({ field }) => (
          <RadioGroup
            {...field}
            onChange={v => {
              field.onChange(v)
              onChangeCallback?.(v)
            }}
          >
            <Stack direction={direction} spacing={4}>
              {options.map(option => (
                <Radio mr={4} key={option.id} value={option.id} {...radioProps}>
                  {radioProps?.isReadOnly ?? false ? (
                    <Text
                      as="span"
                      color="gray.600"
                      cursor={radioProps?.isReadOnly ?? false ? 'not-allowed' : 'auto'}
                    >
                      {option.body}
                    </Text>
                  ) : (
                    option.body
                  )}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
        name={name}
      />
      {errorMessage !== undefined && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

export default Component
