const STORAGE_KEY = 'adam_auth_token'

export type AuthStorageState = {
  token: string
  refreshToken: string
}

export const save = (state: AuthStorageState) => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
}

export const load = (): AuthStorageState | null => {
  try {
    const state = JSON.parse(window.localStorage.getItem(STORAGE_KEY) ?? '')

    return state
  } catch {
    return null
  }
}

export const clear = () => {
  window.localStorage.removeItem(STORAGE_KEY)
}

export const storage = {
  save,
  load,
  clear,
}
