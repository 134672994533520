import React, { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Stack,
} from '@chakra-ui/react'

export type DialogOptions = {
  catchOnCancel?: boolean
  variant: 'alert' | 'confirm'
  title?: string
  description?: string | JSX.Element
  cancelText?: string
  submitText?: string
  submitColor?: string
}

type ConfirmationModalProps = DialogOptions & {
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}

const Component: React.FC<ConfirmationModalProps> = ({
  isOpen,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  cancelText = 'キャンセル',
  submitText = variant === 'alert' ? '閉じる' : 'OK',
  submitColor = 'blue',
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (
    <AlertDialog
      size="xs"
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      closeOnOverlayClick={false}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent width="80vw" height="auto" rounded="sm" p={0}>
        {title != null && (
          <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
            {title}
          </AlertDialogHeader>
        )}

        {description != null && (
          <AlertDialogBody pt={title != null ? 2 : 4}>{description}</AlertDialogBody>
        )}

        <AlertDialogFooter display="block">
          {variant === 'confirm' && (
            <Stack spacing={4}>
              <Button colorScheme={submitColor} variant="solid" onClick={onSubmit} h="40px">
                {submitText}
              </Button>
              <Button colorScheme="gray" variant="ghost" ref={cancelRef} onClick={onClose} h="40px">
                {cancelText}
              </Button>
            </Stack>
          )}
          {variant === 'alert' && (
            <Stack>
              <Button
                ref={cancelRef}
                onClick={onSubmit}
                colorScheme={submitColor}
                variant="solid"
                h="40px"
              >
                {submitText}
              </Button>
            </Stack>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default Component
