import { filter, pipe, subscribe, share } from 'wonka'
import Bugsnag from 'config/bugsnag'
import { SubscriptionForwarder } from 'urql'
import { makeFetchSource } from './utils'
import { createPusher } from './pusher'
import { FetchSource, UpdatePayload } from './types'

type PusherChannelError = {
  type: string
  error: string
  status: number
}

export const createPusherObserver: SubscriptionForwarder = (request, operation) => {
  return {
    subscribe: observer => {
      const pusher = createPusher({
        appKey: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
        cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
      })
      let channelName: string

      const fetchSource = share(makeFetchSource(request, operation))

      // Connect Pusher channel
      const hasMetaData = (data: FetchSource) => 'meta' in data
      pipe(
        fetchSource,
        filter(hasMetaData),
        subscribe(({ meta }) => {
          if (meta?.subscriptionId != null && meta.subscriptionId !== '') {
            channelName = meta.subscriptionId
            const channel = pusher.subscribe(channelName)
            if (channel != null) {
              channel.bind('pusher:subscription_error', (error: PusherChannelError) => {
                if (process.env.NODE_ENV === 'development') {
                  console.warn('channel error', error)
                } else {
                  Bugsnag.notify(Error(error.error), event => {
                    const { query, variables } = operation
                    event.addMetadata('pusher', 'channelName', channelName)
                    event.addMetadata('GraphQL', { query, variables })
                    event.addMetadata('Detail', error)
                  })
                }
              })
              channel.bind('update', (payload: UpdatePayload) => {
                if (payload.result.errors != null) {
                  const [error] = payload.result.errors
                  if (process.env.NODE_ENV === 'development') {
                    console.warn(error)
                  } else {
                    Bugsnag.notify(Error(error.message), event => {
                      const { query, variables } = operation
                      event.addMetadata('pusher', 'channelName', channelName)
                      event.addMetadata('GraphQL', { query, variables })
                    })
                  }
                }
                if (!payload.more) {
                  pusher.unsubscribe(channelName)
                  observer.complete()
                }
                const { result } = payload
                if (result != null) {
                  observer.next(result)
                }
              })
            }
          }
        }),
      )

      // Get initial data
      pipe(
        fetchSource,
        filter(op => !hasMetaData(op)),
        subscribe(data => {
          observer.next(data)
        }),
      )

      return {
        unsubscribe() {
          console.log('unsubscribe')
          pusher.unsubscribe(channelName)
        },
      }
    },
  }
}
