import React, { createContext } from 'react'
import Dialog, { DialogOptions } from 'components/ui/Dialog'

export const Context = createContext<(options: DialogOptions) => Promise<void>>(Promise.reject)

export const Provider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState<DialogOptions | null>(null)

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: DialogOptions) => {
    setConfirmationState(options)

    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject }
    })
  }

  const handleClose = () => {
    if ((confirmationState?.catchOnCancel ?? false) && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <>
      <Context.Provider value={openConfirmation}>{children}</Context.Provider>

      {confirmationState != null && (
        <Dialog isOpen onSubmit={handleSubmit} onClose={handleClose} {...confirmationState} />
      )}
    </>
  )
}
