import { fetchExchange } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import cache from './cache'
import { buildAuthExchange } from './auth'
import { subscription } from './subscription'
import { bugsnagExchange } from './bugsnag'
import { operationNameExchange } from './operationName'

export const exchanges =
  process.env.NODE_ENV === 'development'
    ? [
        devtoolsExchange,
        requestPolicyExchange({}),
        cache,
        operationNameExchange(),
        buildAuthExchange(),
        fetchExchange,
        subscription,
      ]
    : [
        requestPolicyExchange({}),
        cache,
        bugsnagExchange,
        operationNameExchange(),
        buildAuthExchange(),
        fetchExchange,
        subscription,
      ]
