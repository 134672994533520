import 'config/dayjs'
import 'config/validation/locale'

import React, { Suspense } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ChakraProvider } from '@chakra-ui/react'
import { ErrorBoundary } from 'components/functional'
import { Provider as UrqlProvider } from 'config/urql'
import { Provider as IntlProvider } from 'config/intl/Provider'
import customTheme from 'style/theme'
import { ConfirmationProvider } from 'context/confirmation'
import { AuthStateProvider } from 'context/auth/Provider'
import { CSR } from 'utils/csr'
import { PageSpinner } from 'components/ui'

const App = (props: AppProps) => {
  const { Component, pageProps } = props

  return (
    <Suspense fallback={<PageSpinner />}>
      <CSR>
        <ChakraProvider theme={customTheme}>
          <IntlProvider>
            <ErrorBoundary>
              <UrqlProvider>
                <AuthStateProvider>
                  <ConfirmationProvider>
                    <Head>
                      <title>Smaluna for Admin</title>
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, shrink-to-fit=no"
                      />
                    </Head>
                    <Component {...pageProps} />
                  </ConfirmationProvider>
                </AuthStateProvider>
              </UrqlProvider>
            </ErrorBoundary>
          </IntlProvider>
        </ChakraProvider>
      </CSR>
    </Suspense>
  )
}

export default App
