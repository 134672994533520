import { setLocale } from 'yup'

setLocale({
  mixed: {
    default: '入力エラーです',
    required: '入力して下さい',
    oneOf: ({ values }) => `次の値のいずれかでなければなりません: ${values}`,
    notOneOf: ({ values }) => `次の値のいずれかであってはなりません: ${values}`,
    notType: '入力形式が違います',
  },
  string: {
    length: ({ length }) => `${length}文字入力して下さい`,
    min: ({ min }) => `${min}文字以上入力してください`,
    max: ({ max }) => `${max}文字以内で入力して下さい`,
    email: 'メールアドレスが正しくありません',
    url: 'URLが正しくありません',
  },
  number: {
    min: ({ min }) => `${min}以上の値を入力して下さい`,
    max: ({ max }) => `${max}以下の値を入力して下さい`,
    positive: '正の数を入力して下さい',
    negative: '負の数を入力して下さい',
    integer: '整数を入力して下さい',
  },
  array: {
    min: ({ min }) => `${min}以上の値を選択して下さい`,
  },
})
