import { useRef } from 'react'

export const useAttachFile = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onAttachFile = () => inputRef.current?.click()

  const getAttachedFile = () => {
    if (!inputRef.current?.files) return null

    return inputRef.current.files[0] ?? null
  }

  // 同じファイルを選んでもonChangeが動くように、valueを空にしている
  const resetAttachedFile = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return {
    inputRef,
    onAttachFile,
    getAttachedFile,
    resetAttachedFile,
  }
}
