import React from 'react'
import Bugsnag from 'config/bugsnag'
import ErrorPage from 'components/pages/Error'

const DummyErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || DummyErrorBoundary

const Component: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={ErrorPage.Fallback}>{children}</ErrorBoundary>
}

export default Component
