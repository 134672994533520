import Router from 'next/router'
import { Box } from '@chakra-ui/react'
import { SIGN_OUT } from 'constants/path'

const Component = () => {
  return (
    <Box
      as="button"
      color="white"
      w="full"
      textAlign="left"
      fontSize="md"
      fontWeight="normal"
      py={2}
      px={3}
      borderRadius={6}
      bgColor="blue.600"
      onClick={() => Router.push(SIGN_OUT)}
    >
      ログアウト
    </Box>
  )
}

export default Component
