import { UpdateResolver } from '@urql/exchange-graphcache'
import {
  DestroyAdminMutation,
  DestroyAdminMutationVariables,
  RevokeAdminMutation,
  RevokeAdminMutationVariables,
} from 'components/pages/Admin/Detail/DangerCard/Modal/Form/query.gen-graphql'

const destroyAdmin: UpdateResolver<DestroyAdminMutation, DestroyAdminMutationVariables> = (
  result,
  args,
  cache,
) => {
  if (!result.destroyAdmin) return

  cache.invalidate({
    __typename: 'Admin',
    id: args.input.id,
  })
}

const revokeAdmin: UpdateResolver<RevokeAdminMutation, RevokeAdminMutationVariables> = (
  result,
  args,
  cache,
) => {
  if (!result.revokeAdmin) return

  cache.invalidate({
    __typename: 'Admin',
    id: args.input.id,
  })
}

export const updateAdminList = {
  Mutation: { destroyAdmin, revokeAdmin },
}
