import type { Exchange } from '@urql/core'
import { makeOperation, mapExchange } from '@urql/core'
import { getOperationName } from '../utils'

/**
 * ログを見やすくするために operationName をクエリパラメータに付与する
 */
export const operationNameExchange = (): Exchange => {
  return mapExchange({
    onOperation: operation => {
      const url = new URL(operation.context.url)
      const operationName = getOperationName(operation)
      if (operationName !== undefined) {
        url.searchParams.set('operationName', operationName ?? '')
      }

      return makeOperation(operation.kind, operation, {
        ...operation.context,
        url: url.toString(),
      })
    },
  })
}
