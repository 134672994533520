import type { Operation } from '@urql/core'
import { Kind } from 'graphql'

export const getOperationName = (operation: Operation) => {
  const { query } = operation

  for (const node of query.definitions) {
    if (node.kind === Kind.OPERATION_DEFINITION) {
      return node.name ? node.name.value : undefined
    }
  }

  return undefined
}
