import InputField from './InputField'
import PasswordField from './PasswordField'
import Radio from './Radio'
import Textarea from './Textarea'
import Checkbox from './Checkbox'
import Select from './Select'

export default {
  InputField,
  PasswordField,
  Radio,
  Textarea,
  Checkbox,
  Select,
}
