import { Tr, Td } from '@chakra-ui/react'
import { DragHandleIcon } from '@chakra-ui/icons'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import Menu from './Menu'

type Props = {
  draggableProvided: DraggableProvided
  draggableSnapshot: DraggableStateSnapshot
  disabledDragDrop?: boolean
  isTop: boolean
  isBottom: boolean
  rowDataComponent: React.ReactElement
  changePosition: Pick<
    React.ComponentProps<typeof Menu>,
    'toTop' | 'toBottom' | 'oneUp' | 'oneDown'
  >
}

const Component: React.FC<Props> = ({
  draggableProvided,
  draggableSnapshot,
  isTop,
  isBottom,
  disabledDragDrop = false,
  rowDataComponent,
  changePosition,
}) => {
  return (
    <Tr
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      bg={draggableSnapshot.isDragging ? 'gray.100' : undefined}
    >
      {!disabledDragDrop && (
        <Td {...draggableProvided.dragHandleProps}>
          <DragHandleIcon fontSize={10} cursor="grab" />
        </Td>
      )}
      {rowDataComponent}
      {!disabledDragDrop && (
        <Td>
          <Menu isTop={isTop} isBottom={isBottom} {...changePosition} />
        </Td>
      )}
    </Tr>
  )
}

export default Component
