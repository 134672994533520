import { CopyIcon } from '@chakra-ui/icons'
import { Box, Tooltip, VisuallyHidden, useClipboard } from '@chakra-ui/react'

type Props = {
  text: string
}

export const CopyButton: React.FC<Props> = ({ text }) => {
  const { onCopy, hasCopied } = useClipboard(text)

  return (
    <Tooltip label="コピーしました" isOpen={hasCopied}>
      <Box as="button">
        <VisuallyHidden>クリップボードにコピー</VisuallyHidden>
        <CopyIcon onClick={onCopy} cursor="pointer" ml={2} color="blue.500" />
      </Box>
    </Tooltip>
  )
}
