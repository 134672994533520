import Pusher from 'pusher-js'
import Bugsnag from 'config/bugsnag'
import { authorizer } from './authorizer'

type CreatePusher = (options: { appKey: string; cluster: string }) => Pusher

let pusher: Pusher | null

/**
 * Pusher オブジェクト = Pusher connection なので
 * ログイン中に1度だけ初期化する。
 */
export const createPusher: CreatePusher = ({ appKey, cluster }) => {
  if (!pusher) {
    pusher = new Pusher(appKey, {
      cluster,
      authorizer,
      forceTLS: true,
    })

    // @see https://pusher.com/docs/channels/library_auth_reference/pusher-websockets-protocol/#error-codes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pusher.connection.bind('error', (error: any) => {
      if (process.env.NODE_ENV === 'development') {
        console.warn('pusher connection error', error)
      } else {
        const code = Number(error.error?.data?.code ?? 0)
        if ((code >= 4000 && code < 4100) || (code >= 4300 && code < 4400)) {
          Bugsnag.notify(Error('Pusher connection error'), event => {
            event.addMetadata('pusher', 'errorCode', code)
            event.addMetadata('pusher', 'errorDetail', error)
          })
        }
      }
    })
  }

  return pusher
}

/**
 * Pusher connection を切断する。
 * ログアウト時に呼び出す。
 */
export const disconnectPusher = () => {
  if (pusher) {
    pusher.disconnect()
    pusher = null
  }
}
