import React, { useEffect } from 'react'
import Router from 'next/router'

type Props = React.PropsWithChildren<{
  condition: boolean | undefined
  redirectTo: string
  loading?: boolean
}>

const Guard: React.FC<Props> = ({ condition = false, redirectTo, loading = false, children }) => {
  useEffect(() => {
    if (loading) return
    if (condition) {
      Router.replace(redirectTo)
    }
  }, [loading, condition, redirectTo])

  if (!loading && condition) {
    return null
  }

  return <>{children}</>
}

export default Guard
