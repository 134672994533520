import React from 'react'
import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  FormLabelProps,
  CheckboxProps,
  FormErrorMessage,
  Stack,
} from '@chakra-ui/react'
import { Control, Controller, UseFormRegisterReturn } from 'react-hook-form'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  name: UseFormRegisterReturn['name']
  options: { id: string; body: string }[]
  label?: string
  labelProps?: FormLabelProps
  errorMessage?: string
  isRequired?: boolean
  checkboxProps?: CheckboxProps
}

export const Component: React.FC<Props> = ({
  label,
  labelProps,
  errorMessage,
  control,
  options,
  isRequired,
  name,
  checkboxProps,
}) => {
  return (
    <FormControl isInvalid={errorMessage !== undefined} isRequired={isRequired}>
      {label !== undefined && (
        <FormLabel {...labelProps} mb={4}>
          {label}
        </FormLabel>
      )}
      <Controller
        control={control}
        render={({ field: { value, onChange, onBlur, ref } }) => (
          <CheckboxGroup {...{ value, onChange, onBlur }}>
            <Stack>
              {options.map(option => (
                <Checkbox
                  key={option.id}
                  value={option.id}
                  ref={ref}
                  {...checkboxProps}
                  isRequired={false}
                >
                  {option.body}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        )}
        name={name}
      />
      {errorMessage !== undefined && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  )
}

export default Component
