import { UpdateResolver } from '@urql/exchange-graphcache'
import {
  DestroyDoctorOperatingTimeMutation,
  DestroyDoctorOperatingTimeMutationVariables,
} from 'components/model/DoctorOperatingTime/Table/mutation.gen-graphql'

const destroyDoctorOperatingTime: UpdateResolver<
  DestroyDoctorOperatingTimeMutation,
  DestroyDoctorOperatingTimeMutationVariables
> = (result, args, cache) => {
  if (
    !result.destroyDoctorOperatingTime ||
    result.destroyDoctorOperatingTime.doctorOperatingTime.deletedAt === null
  )
    return

  cache.invalidate({
    __typename: 'DoctorOperatingTime',
    id: args.input.doctorOperatingTimeId,
  })
}

export const updateDoctorOperationTime = {
  Mutation: { destroyDoctorOperatingTime },
}
