import React, { ComponentProps } from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { chakra } from '@chakra-ui/react'

type Props = NextLinkProps & {
  isExternal?: boolean
}

const ChakraNextLink = chakra<typeof NextLink, Props>(NextLink, {
  baseStyle: {
    color: 'primary.500',
  },
})

export const Component: React.FC<ComponentProps<typeof ChakraNextLink>> = ({
  isExternal = false,
  ...rest
}) => {
  return (
    <ChakraNextLink
      {...(isExternal ? { target: '_blank', rel: 'noreferrer', textDecoration: 'underline' } : {})}
      {...rest}
    />
  )
}

export default Component
