import { UpdateResolver } from '@urql/exchange-graphcache'
import {
  DestroyFrequentlyAskedQuestionMutation,
  DestroyFrequentlyAskedQuestionMutationVariables,
} from 'components/pages/FrequentlyAskedQuestion/Detail/DangerCard/query.gen-graphql'

const destroyFrequentlyAskedQuestion: UpdateResolver<
  DestroyFrequentlyAskedQuestionMutation,
  DestroyFrequentlyAskedQuestionMutationVariables
> = (result, args, cache) => {
  if (!result.destroyFrequentlyAskedQuestion) return

  cache.invalidate({
    __typename: 'FrequentlyAskedQuestion',
    id: args.input.id,
  })
}

export const updateFrequentlyAskedQuestionList = {
  Mutation: { destroyFrequentlyAskedQuestion },
}
