import { Button } from '@chakra-ui/react'
import { EditIcon } from '@chakra-ui/icons'
import { useAttachFile } from './useAttachFile'
import { usePreview } from './usePreview'
import PreviewModal from './PreviewModal'

type Args = {
  file: File | null
  onCloseModal: () => void
}
type ContainerProps = {
  title: string
  imageTitle?: string
  handleSubmit: ({ file, onCloseModal }: Args) => Promise<void>
  isSubmitting: boolean
  buttonText: string
  acceptFileType: string
}
type Props = {
  attachFile: Omit<ReturnType<typeof useAttachFile>, 'resetAttachedFile'>
  preview: ReturnType<typeof usePreview>
  handleSubmit: () => void
} & ContainerProps

const Component: React.FC<Props> = ({
  title,
  imageTitle,
  buttonText,
  attachFile,
  preview,
  handleSubmit,
  isSubmitting,
  acceptFileType,
}) => {
  return (
    <>
      <Button
        variant="outline"
        colorScheme="blue"
        leftIcon={<EditIcon />}
        size="sm"
        onClick={attachFile.onAttachFile}
      >
        {buttonText}
      </Button>
      <input
        type="file"
        hidden
        accept={acceptFileType}
        ref={attachFile.inputRef}
        onChange={preview.onFileInputChange}
      />
      <PreviewModal
        title={title}
        imageTitle={imageTitle}
        isOpen={preview.isOpen}
        onClose={preview.onClose}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        file={attachFile.getAttachedFile()}
      />
    </>
  )
}

const Container: React.FC<ContainerProps> = ({ handleSubmit, isSubmitting, ...rest }) => {
  const { getAttachedFile, resetAttachedFile, ...attachFile } = useAttachFile()
  const preview = usePreview({ resetAttachedFile })

  return (
    <Component
      {...{
        ...rest,
        ...{ attachFile: { ...attachFile, getAttachedFile } },
        preview,
        handleSubmit: () =>
          handleSubmit({ file: getAttachedFile(), onCloseModal: preview.onClose }),
        isSubmitting,
      }}
    />
  )
}

export default Container
