import { FC, useCallback } from 'react'
import { Table, Tbody } from '@chakra-ui/react'
import {
  DragDropContext as _DragDropContext,
  DragDropContextProps,
  Droppable as _Droppable,
  DroppableProps,
  Draggable as _Draggable,
  DraggableProps,
} from 'react-beautiful-dnd'
import Row from './Row'
import Header from './Header'

// HACK: this fixes type incompatibility
const DragDropContext = _DragDropContext as unknown as FC<DragDropContextProps>
const Droppable = _Droppable as unknown as FC<DroppableProps>
const Draggable = _Draggable as unknown as FC<DraggableProps>

type ContainerProps<T> = {
  data: ReadonlyArray<T>
  headerContent: React.ReactElement
  disabledDragDrop?: boolean
  changePosition: {
    toTop: (id: string) => Promise<void>
    toBottom: (id: string) => Promise<void>
    oneUp: ({ id, position }: { id: string; position: number }) => Promise<void>
    oneDown: ({ id, position }: { id: string; position: number }) => Promise<void>
  }
  renderRowData: (item: T) => React.ReactElement
  handleOnDragEnd: DragDropContextProps['onDragEnd']
}

type Props<T> = Omit<ContainerProps<T>, 'changePosition'> & {
  changePosition: {
    toTop: (id: string) => Promise<void>
    toBottom: (id: string) => Promise<void>
    oneUp: (item: T) => Promise<void>
    oneDown: (item: T) => Promise<void>
  }
}

const Component = <T extends Record<'id', string> & Record<string, unknown>>({
  data,
  headerContent,
  renderRowData,
  handleOnDragEnd,
  disabledDragDrop = false,
  changePosition,
}: Props<T>) => {
  return (
    <Table variant="simple" bg="white">
      <Header headerContent={headerContent} enableChangePosition={!disabledDragDrop} />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable" isDropDisabled={disabledDragDrop}>
          {droppableProvided => (
            <Tbody {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              <>
                {data.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={disabledDragDrop}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <Row
                        draggableProvided={draggableProvided}
                        draggableSnapshot={draggableSnapshot}
                        disabledDragDrop={disabledDragDrop}
                        isTop={index === 0}
                        isBottom={index + 1 === data.length}
                        rowDataComponent={renderRowData(item)}
                        changePosition={{
                          toTop: () => changePosition.toTop(item.id),
                          toBottom: () => changePosition.toBottom(item.id),
                          oneUp: () => changePosition.oneUp(item),
                          oneDown: () => changePosition.oneDown(item),
                        }}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </>
            </Tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  )
}

const Container = <T extends Record<'id', string> & Record<string, unknown>>({
  changePosition,
  ...rest
}: ContainerProps<T>) => {
  const oneUp = useCallback(
    async (item: T) => {
      if ('position' in item && typeof item.position === 'number') {
        changePosition.oneUp({ id: item.id, position: item.position })
      }
    },
    [changePosition],
  )

  const oneDown = useCallback(
    async (item: T) => {
      if ('position' in item && typeof item.position === 'number') {
        changePosition.oneDown({ id: item.id, position: item.position })
      }
    },
    [changePosition],
  )

  return <Component {...rest} changePosition={{ ...changePosition, oneUp, oneDown }} />
}

export default Container
