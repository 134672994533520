import { UpdateResolver } from '@urql/exchange-graphcache'
import {
  DestroyDoctorHolidayMutation,
  DestroyDoctorHolidayMutationVariables,
} from 'components/model/DoctorHoliday/Table/mutation.gen-graphql'

const destroyDoctorHoliday: UpdateResolver<
  DestroyDoctorHolidayMutation,
  DestroyDoctorHolidayMutationVariables
> = (result, args, cache) => {
  if (!result.destroyDoctorHoliday) return

  cache.invalidate({
    __typename: 'DoctorHoliday',
    id: args.input.id,
  })
}

export const updateDoctorHoliday = {
  Mutation: { destroyDoctorHoliday },
}
