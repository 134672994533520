import { jwtDecode } from 'jwt-decode'

type JwtStructure = {
  exp: number
  claims: {
    id: number
    email: string
    role: 'customer'
  }
}

const decodeJwt = (jwt: string) => {
  return jwtDecode<JwtStructure>(jwt)
}

const checkJwtIsExpired = (jwt: string) => {
  const decoded = decodeJwt(jwt)
  const now = Date.now()

  return decoded.exp * 1000 < now
}

export { decodeJwt, checkJwtIsExpired }
