import React, { createContext, useContext, useState, useCallback } from 'react'
import { save, load, AuthStorageState } from 'utils/storage/auth'

type AuthState = {
  signedIn: boolean
}
type SetAuthState = {
  signIn: (authStorageState: AuthStorageState) => void
}

const AuthStateContext = createContext<AuthState>({ signedIn: false })
const SetAuthStateContext = createContext<SetAuthState>({} as SetAuthState)

export const useAuthState = () => useContext(AuthStateContext)
export const useSetAuthState = () => useContext(SetAuthStateContext)

export const AuthStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { token } = load() ?? {}
  const [state, setState] = useState<AuthState>({ signedIn: token !== undefined })

  const signIn = useCallback((authStorageState: AuthStorageState) => {
    save(authStorageState)
    setState({ signedIn: true })
  }, [])

  return (
    <SetAuthStateContext.Provider value={{ signIn }}>
      <AuthStateContext.Provider value={state}>{children}</AuthStateContext.Provider>
    </SetAuthStateContext.Provider>
  )
}
