import { parse, Kind } from 'graphql'
import { Operation, SubscriptionOperation } from 'urql'
import { make } from 'wonka'
import { FetchHeaders, FetchSource } from './types'

export const getFetchOptions = (request: SubscriptionOperation, operation: Operation) => {
  const { context } = operation
  const options =
    typeof context.fetchOptions === 'function' ? context.fetchOptions() : context.fetchOptions
  const headers = {
    ...(options?.headers ?? {}),
    'Content-Type': 'application/json',
  } as FetchHeaders

  return {
    ...options,
    method: 'POST',
    body: JSON.stringify(getFetchBody(request)),
    headers,
  }
}

export const getFetchBody = (operation: SubscriptionOperation) => {
  const { operationName } = operation

  return {
    query: operation.query,
    operationName,
    variables: operation.variables,
  }
}

export const getOperationName = (query: string): string | undefined => {
  const nodes = parse(query)
  for (let i = 0, l = nodes.definitions.length; i < l; i += 1) {
    const node = nodes.definitions[i]
    if (node?.kind === Kind.OPERATION_DEFINITION && node.name) {
      return node.name.value
    }
  }

  return undefined
}

export const makeFetchSource = (request: SubscriptionOperation, operation: Operation) => {
  const { context } = operation
  const fetcher = context.fetch || fetch
  const fetchOptions = getFetchOptions(request, operation)

  return make<FetchSource>(fetchObserver => {
    const { next, complete } = fetchObserver
    let cancelled = false

    fetcher(context.url, fetchOptions)
      .then(res => {
        if (!cancelled) {
          next({
            meta: {
              subscriptionId: res.headers.get('X-Subscription-ID'),
            },
          })
        }

        return res.json()
      })
      .then(data => {
        if (!cancelled) {
          next(data)
          complete()
        }
      })
      .catch(error => {
        throw error
      })

    return () => {
      cancelled = true
    }
  })
}
