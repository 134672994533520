import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { decodeJwt } from 'utils/jwt'
import { storage } from 'utils/storage/auth'

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY != null) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => {
      const { token } = storage.load() ?? {}

      if (token !== undefined) {
        const { claims } = decodeJwt(token)
        event.setUser(claims.id.toString(), claims.email)
        event.addMetadata('role', { role: claims.role })
      }
    },
    onBreadcrumb: breadcrumb => {
      if (breadcrumb.type === 'request' && typeof breadcrumb.metadata.request === 'string') {
        if (
          breadcrumb.metadata.request?.endsWith('/graphql') &&
          breadcrumb.metadata.status === 200
        ) {
          return false
        }
      }
    },
  })
}

export default Bugsnag
