export const HOME = '/'
export const SIGN_IN = '/signin'
export const SIGN_OUT = '/signout'
export const PASSWORD_RESET = '/password/reset'

// 医師
export const DOCTOR_LIST = '/doctors'
export const DOCTOR_NEW = '/doctors/new'
export const DOCTOR_DETAIL = (id: string) => `/doctors/${id}`
export const DOCTOR_EDIT = (id: string) => `/doctors/${id}/edit`
export const DOCTOR_HOLIDAY_LIST = (id: string) => `/doctors/${id}/holidays`
export const DOCTOR_OPERATING_TIME_LIST = (id: string) => `/doctors/${id}/operating_times`

// 管理者
export const ADMIN_LIST = '/admins'
export const ADMIN_NEW = '/admins/new'
export const ADMIN_DETAIL = (id: string) => `/admins/${id}`
export const ADMIN_EDIT = (id: string) => `/admins/${id}/edit`

// 顧客
export const CUSTOMER_LIST = '/customers'
export const CUSTOMER_DETAIL = (id: string) => `/customers/${id}`
export const CUSTOMER_EDIT = (id: string) => `/customers/${id}/edit`
export const CUSTOMER_INVOICE_LIST = (id: string) => `/customers/${id}/invoices`
export const CUSTOMER_EXAMINATION_LIST = (id: string) => `/customers/${id}/examinations`
export const CUSTOMER_MEDICINE_SUBSCRIPTION_LIST = (id: string) =>
  `/customers/${id}/medicine_subscriptions`
export const CUSTOMER_COUPON_LIST = (id: string) => `/customers/${id}/coupons`

// 問い合わせカテゴリ
export const INQUIRY_CATEGORY_LIST = '/inquiry_categories'
export const INQUIRY_CATEGORY_NEW = '/inquiry_categories/new'
export const INQUIRY_CATEGORY_DETAIL = (id: string) => `/inquiry_categories/${id}`

// よくある質問
export const FREQUENTLY_ASKED_QUESTION_DETAIL = (frequentlyAskedQuestionId: string) =>
  `/frequently_asked_questions/${frequentlyAskedQuestionId}`

// Zendeskチケット一括作成
export const TICKETS_CREATION = '/tickets_creation'

// 薬剤
export const MEDICINE_CATEGORY_LIST = '/medicine_categories'
export const MEDICINE_CATEGORY_DETAIL = (id: string) => `/medicine_categories/${id}`
export const MEDICINE_LIST = '/medicines'
export const MEDICINE_DETAIL = (id: string) => `/medicines/${id}`
export const MEDICINE_VARIANT_DETAIL = (id: string) => `/medicine_variants/${id}`

// 問診票
export const INTERVIEW_SHEET_LIST = '/interview_sheets'

// 請求
export const INVOICE_DETAIL = (id: string) => `/invoices/${id}`

// クーポン
export const COUPON_LIST = '/coupons'
export const COUPON_NEW = '/coupons/new'
export const COUPON_DETAIL = (id: string) => `/coupons/${id}`
export const COUPON_DETAIL_CODE_LIST = (id: string) => `/coupons/${id}/codes`
export const COUPON_ACTIVATION_CODE_DETAIL = ({
  couponId,
  codeId,
}: {
  couponId: string
  codeId: string
}) => `/coupons/${couponId}/codes/${codeId}`

// 診察終了理由
export const EXAMINATION_TERMINATING_REASON_LIST = '/examination_terminating_reasons'

// 請求一覧
export const INVOICE_LIST = '/invoices'

// 診察
export const EXAMINATION_LIST = '/examinations'
export const EXAMINATION_DETAIL = (id: string) => `/examinations/${id}`
export const EXAMINATION_DETAIL_INTERVIEWS = (id: string) => `/examinations/${id}/interviews`
export const EXAMINATION_DETAIL_ROUTINE_CHECKUPS = (id: string) =>
  `/examinations/${id}/routine-checkups`
export const EXAMINATION_DETAIL_MESSAGES = (id: string) => `/examinations/${id}/messages`

// 定期便
export const SUBSCRIPTION_DETAIL = (id: string) => `/subscriptions/${id}`

// 定期問診
export const ROUTINE_CHECKUP_SCHEDULE_DETAIL = (id: string) => `/routine_checkup_schedules/${id}`
