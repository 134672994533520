import React from 'react'
import { Provider as UrqlProvider } from 'urql'
import { createClient } from './client'

type ClientState = {
  resetClient: () => void
}

type Props = React.PropsWithChildren<Record<string, unknown>>

const Context = React.createContext<ClientState>({} as ClientState)

export const Provider: React.FC<Props> = ({ children }) => {
  const [client, setClient] = React.useState(createClient())

  return (
    <Context.Provider
      value={{
        resetClient: () => setClient(createClient()),
      }}
    >
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </Context.Provider>
  )
}

export const useClient = () => React.useContext(Context)
