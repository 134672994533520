import { useCallback } from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  SelectProps,
  FormErrorMessage,
  Select,
  forwardRef,
} from '@chakra-ui/react'
import { Control, useController, UseFormRegisterReturn } from 'react-hook-form'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  name: UseFormRegisterReturn['name']
  options: { value: string | number; body: string }[]
  label?: string
  errorMessage?: string
  isRequired?: boolean
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  selectProps: SelectProps
}

const Component = forwardRef<Props, 'select'>(
  ({ label, control, options, errorMessage, name, isRequired, handleChange, selectProps }, ref) => {
    const { field } = useController({ control, name })

    const onChange = useCallback<NonNullable<SelectProps['onChange']>>(
      e => {
        field.onChange(e)
        handleChange?.(e)
      },
      [field, handleChange],
    )

    return (
      <FormControl isInvalid={errorMessage !== undefined} isRequired={isRequired} w="auto">
        {label !== undefined && (
          <Flex justify="space-between">
            <FormLabel>{label}</FormLabel>
          </Flex>
        )}
        <Select {...{ ...selectProps, ...field }} onChange={onChange} ref={ref}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.body}
            </option>
          ))}
        </Select>
        {errorMessage !== undefined && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    )
  },
)

export default Component
