import { Box, Flex, Heading, BoxProps } from '@chakra-ui/react'

type Props = React.PropsWithChildren<{
  title: string
  action?: React.ReactNode
  containerStyle?: BoxProps
}>

const Component: React.FC<Props> = ({ title, action, children, containerStyle }) => (
  <Box as="section" bg="white" rounded="lg" shadow="base" overflow="hidden" {...containerStyle}>
    <Box mx="auto">
      <Flex
        align="center"
        justify="space-between"
        minH={16}
        px="6"
        borderBottomWidth={children !== undefined ? '1px' : undefined}
      >
        <Heading fontSize="lg">{title}</Heading>
        {action}
      </Flex>
      {children}
    </Box>
  </Box>
)

export default Component
