import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  FormErrorMessage,
  forwardRef,
} from '@chakra-ui/react'
import Icons from '../../Icons'

type ContainerProps = InputProps & {
  errorMessage?: string
  isRequired?: boolean
}

type Props = {
  isOpen: boolean
  onClickReveal: () => void
} & ContainerProps

const Component = forwardRef<Props, 'input'>(
  ({ isOpen, onClickReveal, errorMessage, isRequired, ...rest }, ref) => {
    return (
      <FormControl isInvalid={errorMessage !== undefined} id="password" isRequired={isRequired}>
        <Flex justify="space-between">
          <FormLabel>パスワード</FormLabel>
        </Flex>
        <InputGroup>
          <Input
            type={isOpen ? 'text' : 'password'}
            autoComplete="current-password"
            {...rest}
            ref={ref}
          />
          <InputRightElement>
            <IconButton
              bg="transparent !important"
              variant="ghost"
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={isOpen ? <Icons.Eye /> : <Icons.EyeClosed />}
              onClick={onClickReveal}
            />
          </InputRightElement>
        </InputGroup>
        {errorMessage !== undefined && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    )
  },
)

const Container = forwardRef<ContainerProps, 'input'>((props, ref) => {
  const { isOpen, onToggle: onClickReveal } = useDisclosure()

  return <Component {...{ ...props, onClickReveal, isOpen, ref }} />
})

export default Container
