import Head from 'next/head'
import { Flex, Box } from '@chakra-ui/react'
import Sidebar from './Sidebar'

type Props = React.PropsWithChildren<{
  title: string
}>

const Component: React.FC<Props> = ({ title, children }) => (
  <>
    <Head>
      <title>{`${title} | Smaluna for Admin`}</title>
    </Head>
    <main>
      <Flex>
        <Sidebar />
        <Box width="85%">{children}</Box>
      </Flex>
    </main>
  </>
)

export default Component
