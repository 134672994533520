/* eslint-disable @typescript-eslint/ban-ts-comment */
import { cacheExchange } from '@urql/exchange-graphcache'
import schema from 'gen/schema.json'
import { updateDoctorHoliday } from './DoctorHoliday'
import { updateDoctorOperationTime } from './DoctorOperatingTime'
import { updateAdminList } from './Admin'
import { updateFrequentlyAskedQuestionList } from './FrequentlyAskedQuestion'

const cache = cacheExchange({
  keys: {
    InterviewAnswerContent: () => null,
    MediaImage: () => null,
    MediaPostsPayload: () => null,
    MediaPostCategoriesPayload: () => null,
    CustomerAchievement: () => null,
    Role: () => null,
    ImageUrlVariants: () => null,
    DiseaseDoctor: () => null,
    CouponConditionDefinition: () => null,
    CouponConditionOperator: () => null,
    CouponConditionValueObject: () => null,
    CouponCondition: () => null,
  },
  resolvers: {
    Query: {},
  },
  updates: {
    Mutation: {
      ...updateDoctorHoliday.Mutation,
      ...updateDoctorOperationTime.Mutation,
      ...updateAdminList.Mutation,
      ...updateFrequentlyAskedQuestionList.Mutation,
    },
  },
  // @ts-ignore
  schema,
})

export default cache
