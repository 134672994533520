import { createClient as createUrqlClient } from 'urql'
import { ClientOptions } from '@urql/core'
import { exchanges } from './exchanges'

export const createClient = () => {
  const config: ClientOptions = {
    url: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    exchanges,
  }

  return createUrqlClient(config)
}
