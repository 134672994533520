import Linkify from 'linkify-react'
import Link from '../Link'
import { IntermediateRepresentation } from 'linkifyjs'

type Props = React.PropsWithChildren<Omit<React.ComponentProps<typeof Link>, 'href'>>

export const HyperLink: React.FC<Props> = ({ children, ...rest }) => {
  const renderLink = ({ attributes, content }: IntermediateRepresentation) => {
    const { href } = attributes
    return (
      <Link
        href={href}
        color="blue.500"
        textDecoration="underline"
        wordBreak="break-all"
        isExternal
        {...rest}
      >
        {content}
      </Link>
    )
  }

  return <Linkify options={{ render: renderLink }}>{children}</Linkify>
}
