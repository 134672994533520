import { useDisclosure } from '@chakra-ui/react'

type Args = {
  resetAttachedFile: () => void
}

export const usePreview = ({ resetAttachedFile }: Args) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleClose = () => {
    resetAttachedFile() // 同じファイルを選んでも onChange が動くようにリセットする
    onClose()
  }

  return {
    isOpen,
    onClose: handleClose,
    onFileInputChange: onOpen,
  }
}
