import React from 'react'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputProps,
  FormErrorMessage,
  forwardRef,
} from '@chakra-ui/react'
import { UseFormRegisterReturn } from 'react-hook-form'

type Props = InputProps &
  UseFormRegisterReturn & {
    label?: string
    errorMessage?: string
    isRequired?: boolean
  }

const Component = forwardRef<Props, 'input'>(
  ({ label, errorMessage, isRequired, type, ...rest }, ref) => (
    <FormControl isInvalid={errorMessage !== undefined} isRequired={isRequired} w="auto">
      {label !== undefined && (
        <Flex justify="space-between">
          <FormLabel>{label}</FormLabel>
        </Flex>
      )}
      <Input {...rest} ref={ref} type={type} borderStyle={type === 'file' ? 'none' : 'solid'} />
      {errorMessage !== undefined && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  ),
)

export default Component
