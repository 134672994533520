import { Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react'
import MoreVerticalIcon from './MoreVerticalIcon'

type Props = {
  isTop: boolean
  isBottom: boolean
  toTop: () => Promise<void>
  toBottom: () => Promise<void>
  oneUp: () => Promise<void>
  oneDown: () => Promise<void>
}

const Component: React.FC<Props> = ({ isTop, isBottom, toTop, toBottom, oneUp, oneDown }) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<MoreVerticalIcon />}
        variant="ghost"
        _focus={{
          bg: 'white',
          outline: 'none',
        }}
        _hover={{
          bg: 'white',
        }}
        _active={{
          bg: 'white',
        }}
      />
      <MenuList>
        <MenuItem isDisabled={isTop} onClick={toTop}>
          先頭に移動
        </MenuItem>
        <MenuItem isDisabled={isTop} onClick={oneUp}>
          一つ上に移動
        </MenuItem>
        <MenuItem isDisabled={isBottom} onClick={oneDown}>
          一つ下に移動
        </MenuItem>
        <MenuItem isDisabled={isBottom} onClick={toBottom}>
          末尾に移動
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default Component
