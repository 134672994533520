import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  styles: {
    global: {
      html: {
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      body: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        bg: 'lightgray',
        color: 'black',
        fontSize: '14px',
      },
      '#__next': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
  },
})

export default theme
