import React from 'react'
import { Box, Stack, Heading, Divider } from '@chakra-ui/react'
import {
  HOME,
  DOCTOR_LIST,
  ADMIN_LIST,
  INQUIRY_CATEGORY_LIST,
  CUSTOMER_LIST,
  TICKETS_CREATION,
  MEDICINE_CATEGORY_LIST,
  INTERVIEW_SHEET_LIST,
  COUPON_LIST,
  EXAMINATION_TERMINATING_REASON_LIST,
  INVOICE_LIST,
  EXAMINATION_LIST,
} from 'constants/path'
import MenuItem from './MenuItem'
import SignOutButton from './SignOutButton'

const Component = () => {
  return (
    <Box position="sticky" top="0" height="100vh" width="15%" py={8} px={5} bg="blue.600">
      <Heading as="h1" textAlign="center" size="md" fontWeight="extrabold" color="white" mb="10">
        Smaluna for Admin
      </Heading>
      <Stack spacing={3}>
        <MenuItem href={HOME}>ホーム</MenuItem>
        <MenuItem href={ADMIN_LIST}>管理者一覧</MenuItem>
        <MenuItem href={DOCTOR_LIST}>医師一覧</MenuItem>
        <MenuItem href={CUSTOMER_LIST}>顧客一覧</MenuItem>
        <MenuItem href={INVOICE_LIST}>請求一覧</MenuItem>
        <MenuItem href={EXAMINATION_LIST}>診察一覧</MenuItem>
        <MenuItem href={MEDICINE_CATEGORY_LIST}>薬剤一覧</MenuItem>
        <MenuItem href={INTERVIEW_SHEET_LIST}>問診票一覧</MenuItem>
        <MenuItem href={COUPON_LIST}>クーポン</MenuItem>
        <MenuItem href={EXAMINATION_TERMINATING_REASON_LIST}>診察終了理由</MenuItem>
      </Stack>
      <Divider my={6} />
      <Stack spacing={3}>
        <MenuItem href={INQUIRY_CATEGORY_LIST}>問い合わせカテゴリ一覧</MenuItem>
        <MenuItem href={TICKETS_CREATION}>Zendeskチケット作成</MenuItem>
      </Stack>
      <Divider my={6} />
      <SignOutButton />
    </Box>
  )
}

export default Component
