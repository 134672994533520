import React from 'react'

type Props = {
  authorized: boolean
  children: React.ReactElement
}

const Component: React.FC<Props> = ({ authorized, children }) => {
  if (!authorized) {
    return null
  }

  return children
}

export default Component
