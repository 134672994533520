import delay from 'delay'
import { AuthorizerGenerator, AuthorizerCallback } from 'pusher-js'
import { storage } from 'utils/storage/auth'
import Bugsnag from 'config/bugsnag'
import { CombinedError } from 'urql'

type AuthData = Parameters<AuthorizerCallback>[1]
type RefreshResponse = {
  data: {
    refreshAuthToken?: {
      __typename: 'AuthToken'
      token: string
      refreshToken: string
    }
  }
  error?: CombinedError
}

const REFRESH = `
  mutation RefreshAuthTokenPusherAuth($token: String!) {
    refreshAuthToken(input: { refreshToken: $token }) {
      __typename
      token
      refreshToken
    }
  }
`

let refreshing = false

export const authorizer: AuthorizerGenerator = channel => {
  return {
    authorize(socketId: string, callback: AuthorizerCallback) {
      const authorizeOrRefreshToken = async (retry = 1) => {
        try {
          const data = await authorizePusherChannel(channel.name, socketId)
          callback(null, data)
        } catch (e) {
          if (refreshing) {
            // 複数の channel 接続時に同時に refreshAuthToken を試行するのを防ぐ
            await delay(1000)
          } else {
            await refreshAuthToken()
          }
          if (retry > 0) {
            authorizeOrRefreshToken(retry - 1)
          } else {
            callback(Error('Pusher channel auth rejected'), { auth: '' })
          }
        }
      }
      authorizeOrRefreshToken()
    },
  }
}

const authorizePusherChannel = async (channelName: string, socketId: string) => {
  const { token } = storage.load() ?? {}
  /* eslint-disable no-undef */
  const fetchOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ socket_id: socketId, channel_name: channelName }),
  }

  return fetch(process.env.NEXT_PUBLIC_PUSHER_AUTH_ENDPOINT, fetchOptions)
    .then(res => {
      if (res.status === 200) {
        return res
      }
      if (res.status === 403) {
        throw Error('Invalid or expired auth token provided.')
      } else {
        const error = Error('Internal server error')
        Bugsnag.notify(error, event => {
          event.addMetadata('fetch', 'url', process.env.NEXT_PUBLIC_PUSHER_AUTH_ENDPOINT)
          event.addMetadata('fetch', 'options', fetchOptions)
          event.addMetadata('fetch', 'status', res.status)
        })
        throw error
      }
    })
    .then<AuthData>(res => res.json())
}

const refreshAuthToken = async () => {
  try {
    refreshing = true
    const { token, refreshToken } = storage.load() ?? {}

    const data = await fetch(process.env.NEXT_PUBLIC_GRAPHQL_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: REFRESH,
        variables: { token: refreshToken },
      }),
    }).then<RefreshResponse>(res => res.json())
    const result = data.data?.refreshAuthToken
    const newToken = result?.token
    const newRefreshToken = result?.refreshToken

    if (newToken !== undefined && newRefreshToken !== undefined) {
      storage.save({ token: newToken, refreshToken: newRefreshToken })
    } else {
      throw Error(`RefreshAuthToken error: ${data.error}`)
    }
  } catch (e) {
    // Bugsnag.notify(e)
  } finally {
    refreshing = false
  }
}
