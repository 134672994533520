import { Exchange, Operation } from '@urql/core'
import { DefinitionNode, OperationDefinitionNode } from 'graphql'
import { filter, merge, pipe, share, tap } from 'wonka'
import Bugsnag from 'config/bugsnag'

const getOperationDefinition = (
  definitions: readonly DefinitionNode[],
): OperationDefinitionNode | undefined => {
  const [def] = definitions.filter(
    (_def): _def is OperationDefinitionNode => _def.kind === 'OperationDefinition',
  )

  return def
}

export const bugsnagExchange: Exchange = ({ forward }) => {
  return operations$ => {
    const shared = share(operations$)

    const isTarget = (op: Operation) =>
      op.kind === 'query' || op.kind === 'mutation' || op.kind === 'subscription'

    const operations = pipe(
      shared,
      filter(isTarget),
      tap(op => {
        const def = getOperationDefinition(op.query.definitions)
        if (def?.name?.value !== undefined) {
          const name = def.name.value
          Bugsnag.leaveBreadcrumb(`[GraphQL] ${def.operation} ${name}`, undefined, 'request')
        }
      }),
    )
    const others = pipe(
      shared,
      filter(op => !isTarget(op)),
    )

    const operationResult$ = pipe(
      merge([operations, others]),
      forward,
      tap(({ error, operation: op }) => {
        if (error) {
          if (error.graphQLErrors.length > 0) {
            const def = getOperationDefinition(op.query.definitions)
            if (def?.name?.value !== undefined) {
              const name = def.name.value
              Bugsnag.leaveBreadcrumb(
                error.message,
                {
                  operation: `${def.operation} ${name}`,
                },
                'error',
              )
            }
          } else {
            Bugsnag.notify(error, event => {
              const { query, variables } = op
              event.addMetadata('GraphQL', { query, variables })
              event.addMetadata('Detail', error)
            })
          }
        }
      }),
    )

    return operationResult$
  }
}
